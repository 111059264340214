import React, { useState, useEffect, useRef, useMemo } from 'react';
import mapData from '../data/map_data.json';
import mobData from '../data/mob_data.json';
import expData from '../data/exp_data.json';
import potionData from '../data/potion_data.json';
import * as htmlToImage from 'html-to-image';
import { setInterval, clearInterval } from 'worker-timers';
import Adfit from './Adfit';

function Timer() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [isAlarm, setIsAlarm] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [mapInput, setMapInput] = useState("");
  const [mapSearchResult, setMapSearchResult] = useState([]);
  const [selectedMap, setSelectedMap] = useState(localStorage.getItem('selectedMap') || "");
  
  const [elapsedTime, setElapsedTime] = useState(0);
  const [showSearchResult, setShowSearchResult] = useState(false);
  
  const [startExp, setStartExp] = useState(() => Number(localStorage.getItem('startExp')) || 0);
  const [startMeso, setStartMeso] = useState(() => Number(localStorage.getItem('startMeso')) || 0);
  const [endExp, setEndExp] = useState(() => Number(localStorage.getItem('endExp')) || 0);
  const [endMeso, setEndMeso] = useState(() => Number(localStorage.getItem('endMeso')) || 0);
  const [startLv, setStartLv] = useState(() => Number(localStorage.getItem('startLv')) || 0);
  const [endLv, setEndLv] = useState(() => Number(localStorage.getItem('endLv')) || 0);
  
  const [startHP, setStartHP] = useState(() => Number(localStorage.getItem('startHP')) || 0);
  const [endHP, setEndHP] = useState(() => Number(localStorage.getItem('endHP')) || 0);
  const [startMP, setStartMP] = useState(() => Number(localStorage.getItem('startMP')) || 0);
  const [endMP, setEndMP] = useState(() => Number(localStorage.getItem('endMP')) || 0);
  const [startBuff, setStartBuff] = useState(() => Number(localStorage.getItem('startBuff')) || 0);
  const [endBuff, setEndBuff] = useState(() => Number(localStorage.getItem('endBuff')) || 0);

  const [priceHP, setPriceHP] = useState(() => Number(localStorage.getItem('priceHP')) || 0);
  const [priceMP, setPriceMP] = useState(() => Number(localStorage.getItem('priceMP')) || 0);
  const [priceBuff, setPriceBuff] = useState(() => Number(localStorage.getItem('priceBuff')) || 0);

  const [itemAcquired, setItemAcquired] = useState({});
  const intervalRef = useRef(null);
  const [clockMode, setClockMode] = useState(false);
  const [startElapsedTime, setStartElapsedTime] = useState(0);

  const [saveOn, setSaveOn] = useState(false);
  
  const [selectedPotion, setSelectedPotion] = useState(
    localStorage.getItem('selectedPotion') 
    ? JSON.parse(localStorage.getItem('selectedPotion')) 
    : {
      'hp': potionData['hp']['2000000'],
      'mp': potionData['mp']['2000003'],
      'buff': potionData['buff']['2002003']
  });

  const [huntingLog, setHuntingLog] = useState(
    localStorage.getItem('huntingLog') 
    ? JSON.parse(localStorage.getItem('huntingLog')) 
    : []
  );

  const audio = useMemo(() => new Audio("alarm.wav"), ["alarm.wav"]);

  const isAlarmRef = useRef(isAlarm);
  isAlarmRef.current = isAlarm;

  useEffect(() => {
    if ('Notification' in window) {
      if (Notification.permission !== "granted") {
        Notification.requestPermission();
      }
    }

    audio.load();
  }, []);

  useEffect(() => {
    setElapsedTime(0);
  }, [clockMode]);

  useEffect(() => {
    localStorage.setItem('huntingLog', JSON.stringify(huntingLog));
  }, [huntingLog])

  // 값이 변경될 때마다 로컬 스토리지에 저장
  useEffect(() => {
    localStorage.setItem('startExp', startExp);
    localStorage.setItem('startMeso', startMeso);
    localStorage.setItem('endExp', endExp);
    localStorage.setItem('endMeso', endMeso);
    localStorage.setItem('startLv', startLv);
    localStorage.setItem('endLv', endLv);
    localStorage.setItem('startHP', startHP);
    localStorage.setItem('endHP', endHP);
    localStorage.setItem('startMP', startMP);
    localStorage.setItem('endMP', endMP);
    localStorage.setItem('startBuff', startBuff);
    localStorage.setItem('endBuff', endBuff);
    localStorage.setItem('priceHP', priceHP);
    localStorage.setItem('priceMP', priceMP);
    localStorage.setItem('priceBuff', priceBuff);
    localStorage.setItem('selectedPotion', JSON.stringify(selectedPotion));
    localStorage.setItem('selectedMap', selectedMap);
  }, [
    startExp,
    startMeso,
    endExp,
    endMeso,
    startLv,
    endLv,
    startHP,
    endHP,
    startMP,
    endMP,
    startBuff,
    endBuff,
    priceHP,
    priceMP,
    priceBuff,
    selectedPotion,
    selectedMap
  ]);

  const startTimer = () => {
    if (!isActive && !clockMode && elapsedTime !== 0) {
      setIsActive(true);
      intervalRef.current = setInterval(() => {
        

        let oneSec = -1000;
        setElapsedTime((prevElapsedTime) => {
          if (prevElapsedTime + oneSec <= 0) {
            resetTimer();
            sendNoti();
            if (isAlarmRef.current) {
              audio.play();
            }
            return 0;
          }
          return prevElapsedTime + oneSec;
        });
      }, 1000); // 1초 간격으로 업데이트

    } else if (!isActive && clockMode) {

      setIsActive(true);
      intervalRef.current = setInterval(() => {
        let oneSec = 1000;
        setElapsedTime((prevElapsedTime) => prevElapsedTime + oneSec);
      }, 1000); // 1초 간격으로 업데이트
    }
  };

  const pauseTimer = () => {
    if (isActive && intervalRef.current !== null) {
      setIsActive(false);
      clearInterval(intervalRef.current);
      intervalRef.current = null; // 타이머 ID 초기화
    }
  };

  const resetTimer = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null; // 타이머 ID 초기화
    }
    setIsActive(false);
    setElapsedTime(0);
  };

  const formatTime = (ms) => {
    let seconds = Math.floor(ms / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
  
    seconds = seconds % 60;
    minutes = minutes % 60;
  
    return [hours, minutes, seconds];
  }

  const formatTimeString = ([hours, minutes, seconds]) => {
    return `${hours}시간 ${minutes}분 ${seconds}초`;
  }

  const formatTimeComponent = ([hours, minutes, seconds]) => {
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return (
      <div className="grid grid-flow-col gap-1 text-center auto-cols-max">
        <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
          <span className="countdown font-mono text-5xl">
            <span style={{"--value":formattedHours}}></span>
          </span>
          hours
        </div> 
        <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
          <span className="countdown font-mono text-5xl">
            <span style={{"--value":formattedMinutes}}></span>
          </span>
          min
        </div> 
        <div className="flex flex-col p-2 bg-neutral rounded-box text-neutral-content">
          <span className="countdown font-mono text-5xl">
            <span style={{"--value":formattedSeconds}}></span>
          </span>
          sec
        </div>
      </div>
    );
  }

  const getTimeString = (ms) => {
    let seconds = Math.floor(ms / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
  
    seconds = seconds % 60;
    minutes = minutes % 60;
  
    // 각 단위를 두 자리 숫자로 포맷
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const mapInputEvent = (e) => {
    let inputString = e.target.value;
    setMapInput(inputString);
    inputString = inputString.replaceAll(" ", "");
    if (!inputString) return ;
    setMapSearchResult([]);
    let tempResult = []
    for (const [name, mobs] of Object.entries(mapData)) {
      let count = 0;
      let begin = -1;
      const indexList = [];
      for (let i = 0; i < inputString.length; i++) {
        const index = name.indexOf(inputString[i], begin);
        if (index !== -1) {
          begin = index;
          indexList.push(index);
          count++;
        } else {
          break;
        }
      }
      if (count === inputString.length) {
        let sum = indexList.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        tempResult.push([sum, name]);
        tempResult.sort((a, b) => a[0] - b[0]);
        setMapSearchResult(tempResult.map(innerArray => innerArray[1]));
      }
    }
    if (tempResult.length) {
      setShowSearchResult(true);
    }
  }

  const mapInputBlurEvent = () => {
    setTimeout(() => {
      setShowSearchResult(false);
    }, 1000)
  }

  const searchResultComponent = () => {
    return <div 
      className='card bg-base-200 shadow-xl'
      style={{
        position: 'absolute',
        top: '55px',
        padding: "10px",
        zIndex: 999
      }}
    >
      {
        mapSearchResult.slice(0, 10).map(m => 
          (<div 
            key={m}
            onClick={() => {
              setSelectedMap(m);
              setMapInput(m);
              setShowSearchResult(false);
            }}
            style={{cursor: 'pointer'}}
            className='search-result-map-name-div card bg-base-100 mb-1 p-2'>{m}</div>)
        )
      }
    </div>
  }

  const titleComponent = () => {
    return <div className='card bg-base-200 p-4 mb-4'>
      <h1 className='text-3xl font-bold m-2'>{selectedMap}</h1>
    </div>
  }

  const itemListComponent = () => {
    if (!selectedMap || !mapData[selectedMap]) {
      return "";
    }

    return (
      <div className='card bg-base-200 p-4 w-full mt-2'>
        <h3 className='text-lg font-bold text-center'>몹 드랍 아이템</h3>
        <p className='text-xs text-center'>(몹에 마우스를 올리세요!)</p>
        <div className='card bg-base-200 p-4 mb-4'>
          <div className='flex flex-row justify-center flex-wrap'>
            {
              mapData[selectedMap].map(mob => (
                <div key={mob} className="dropdown dropdown-hover">
                  <div tabIndex="0" role="button" className="btn m-1 p-1 glass">
                    <img width={50} src={mobData[mob]["image"]} alt={mob}/>
                  </div>
                  <ul tabIndex="0" className="dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-52 h-56 overflow-scroll mb-10">
                    {
                      mobData[mob]["items"].map(item => (
                        <li key={item[0]}>
                          <button onClick={() => {
                            if (itemAcquired[item[0]]) {
                              // 이미 해당 아이템이 존재하는 경우, count만 증가시키고, 나머지는 그대로 복사
                              setItemAcquired(prevItemAcquired => ({
                                ...prevItemAcquired,
                                [item[0]]: {
                                  ...prevItemAcquired[item[0]],
                                  count: prevItemAcquired[item[0]]['count'] + 1
                                }
                              }));
                            } else {
                              // 새로운 아이템을 추가하는 경우, 전체 객체를 새롭게 구성
                              setItemAcquired(prevItemAcquired => ({
                                ...prevItemAcquired,
                                [item[0]]: {
                                  image: item[1],
                                  count: 1
                                }
                              }));
                            }                            
                          }}
                          className='btn btn-sm w-full flex flex-row p-1 justify-start btn-ghost'>
                            <img width={20} src={item[1]} alt={item[0]}/>
                            <div className='pl-1'>{item[0]}</div>
                          </button>
                        </li>
                      ))
                    }
                  </ul>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    )
  }

  const startAddCounting = (item) => {
    if (intervalRef.current !== null) return; // 이미 카운팅이 시작된 경우 무시
    intervalRef.current = setInterval(() => {
      setItemAcquired(prevItemAcquired => ({
        ...prevItemAcquired,
        [item[0]]: {
          ...prevItemAcquired[item[0]],
          count: prevItemAcquired[item[0]]['count'] + 1
        }
      }));
    }, 100); // 100ms마다 count 증가
  };

  const stopAddCounting = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  const itemAcquiredComponent = () => {
    return <div className='mt-2'>
      <div className='flex flex-col flex-wrap'>
        {
          Object.entries(itemAcquired).map(item => (
            <div key={item[0]} className='flex flex-row mt-2 mb-2'>
              <img className='mr-1' width={22} src={item[1]["image"]} alt={item[0]}/>
              <div className='flex flex-row justify-between w-full'>
                <span className='font-bold pl-1 text-sm flex flex-row justify-center items-center'>{item[0]}</span>
                <div className='flex flex-row'>
                  <button onClick={() => {
                    if (item[1]['count'] === 1) {
                      const { [item[0]]: _, ...newState } = itemAcquired;
                      setItemAcquired(newState);
                    } else {
                      setItemAcquired(prevItemAcquired => ({
                        ...prevItemAcquired,
                        [item[0]]: {
                          ...prevItemAcquired[item[0]],
                          count: prevItemAcquired[item[0]]['count'] - 1
                        }
                      }));
                    }
                  }}
                  className='btn btn-circle btn-ghost btn-xs font-light'>-</button>
                  <div className='pl-1 pr-1'>{item[1]['count']}</div>
                  <button 
                    onClick={() => {
                      setItemAcquired(prevItemAcquired => ({
                        ...prevItemAcquired,
                        [item[0]]: {
                          ...prevItemAcquired[item[0]],
                          count: prevItemAcquired[item[0]]['count'] + 1
                        }
                      }));
                    }}
                    onMouseDown={() => {startAddCounting(item)}}
                    onMouseUp={() => {stopAddCounting(item)}}
                    onMouseLeave={() => {stopAddCounting(item)}}
                    className='btn btn-circle btn-ghost btn-xs font-light'>+</button>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  }

  const fNum = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const calc5Min = (num, et) => {
    if (et < 60000) return 0;
    if (num === 0) return 0;
    return Math.floor((num / (et / 60000))) * 5;
  }

  const calcExpPercent = (lv, exp) => {
    if (lv) {
      return ((exp / expData['exp'][lv - 1]) * 100).toFixed(2);
    }
    return 0;
  }

  const getLvup = (beforeLv, beforeExp, afterLv, afterExp) => {
    return `${beforeLv}(${calcExpPercent(beforeLv, beforeExp)}%) → ${afterLv}(${calcExpPercent(afterLv, afterExp)}%)`
  }

  const getExp = (beforeLv, beforeExp, afterLv, afterExp) => {
    if (beforeLv && afterLv) {
      return (expData['total'][afterLv - 1] + (+afterExp)) - (expData['total'][beforeLv - 1] + (+beforeExp));
    }
    return 0;
  }

  const potionSelectComponent = (potionType) => {
    if (!potionType || !selectedPotion[potionType]) return <></>;
    return (
      <div className="dropdown dropdown-hover">
        <div tabIndex={0} role="button">
          <img width={24} src={selectedPotion[potionType]['image']} alt={selectedPotion[potionType]['name']}/>
        </div>
        <ul tabIndex={0} className="dropdown-content z-[1] p-2 shadow bg-base-100 rounded-box w-52 h-56 overflow-scroll mb-10">
          {
            Object.values(potionData[potionType]).map(item => (
              <li key={item['id']}>
                <button onClick={() => {
                  setSelectedPotion(prev => ({
                    ...prev,
                    [potionType]: item
                  }));
                }}
                className='btn btn-sm w-full flex flex-row p-1 justify-start btn-ghost'>
                  <img width={20} src={item['image']} alt={item['name']}/>
                  <div className='pl-1'>{item['name']}</div>
                </button>
              </li>
            ))
          }
        </ul>
      </div>
    );
  }

  const getDate = () => {
    // 현재 날짜와 시간을 가져옵니다.
    const now = new Date();

    // 연도, 월, 일, 시간, 분, 초를 가져옵니다.
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해주고, 2자리로 맞춥니다.
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    // 형식에 맞게 문자열을 합칩니다.
    const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;

    return formattedDate;
  }

  const sendNoti = () => {
    if (Notification.permission === "granted") {
      const noti = new Notification("메이플랜드 사냥 타이머", {
        icon: "favicon.png",
        body: "타이머가 종료됐습니다!"
      })
    }
  }

  const saveHuntingLog = () => {
    const id = crypto.randomUUID();
    console.log(id);
    const log = {
      id: id,
      map: selectedMap,
      startLv: startLv,
      startExp: startExp,
      startMeso: startMeso,
      startHP: startHP,
      startMP: startMP,
      startBuff: startBuff,
      endLv: endLv,
      endExp: endExp,
      endMeso: endMeso,
      endHP: endHP,
      endMP: endMP,
      endBuff: endBuff,
      priceHP: priceHP,
      priceMP: priceMP,
      priceBuff: priceBuff,
      itemAcquired: itemAcquired,
      selectedPotion: selectedPotion,
      time: elapsedTime,
      date: getDate(),
    };
    setHuntingLog([log, ...huntingLog]);
  }

  const setHuntingLogToInput = (log) => {
    setStartExp(log.startExp);
    setStartMeso(log.startMeso);
    setEndExp(log.endExp);
    setEndMeso(log.endMeso);
    setStartLv(log.startLv);
    setEndLv(log.endLv);
    setStartHP(log.startHP);
    setEndHP(log.endHP);
    setStartMP(log.startMP);
    setEndMP(log.endMP);
    setStartBuff(log.startBuff);
    setEndBuff(log.endBuff);
    setPriceHP(log.priceHP);
    setPriceMP(log.priceMP);
    setPriceBuff(log.priceBuff);
    setSelectedPotion(log.selectedPotion);
    setSelectedMap(log.map);
    setItemAcquired(log.itemAcquired);
    setElapsedTime(log.time);
  }

  const huntingLogComponent = () => {
    return (
      <div className='card bg-base-200 p-4 w-full mt-2'>
        <h3 className='text-lg font-bold text-center mb-1'>나의 사냥 기록</h3>
        <p className='text-xs text-center mb-2'>
          사냥 기록은 브라우저의 로컬 스토리지에 저장됩니다.
        </p>
        <div className='flex flex-row justify-center gap-2'>
          <button className='btn btn-xs btn-info' onClick={() => {
            const file = new Blob([JSON.stringify(huntingLog)], { type: 'application/json' });
            const url = URL.createObjectURL(file);
            const a = document.createElement('a');
            a.href = url;
            a.download = `hunting_log_backup_${getDate()}.json`;
            a.click();
          }}>파일로 백업</button>
          <button className='btn btn-xs btn-info' onClick={() => {
            const confirm = window.confirm('백업파일을 업로드하면 기존 사냥 기록이 삭제됩니다. 백업파일을 업로드하시겠습니까?')
            if (confirm) {
              const input = document.createElement('input');
              input.type = 'file';
              input.accept = 'application/json';
              input.onchange = (e) => {
                const file = e.target.files[0];
                if (file) {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    const content = e.target.result;
                    const log = JSON.parse(content);
                    setHuntingLog(log);
                  };
                  reader.readAsText(file);
                }
              };
              input.click();
            }
          }}>백업파일 업로드</button>
        </div>
        <div className='divider'></div>
        <p className='text-xs text-center mb-2 text-orange-500'>
          각 기록을 클릭하면 저장된 값이 입력 칸에 채워집니다.
        </p>
        <div className='flex flex-col max-h-[600px] overflow-scroll'>
          {
            huntingLog.length > 0
            ?
            huntingLog.map(log => (
              <div className='flex flex-col bg-base-100 p-4 card mb-2 cursor-pointer hover:bg-base-300' onClick={() => {
                setHuntingLogToInput(log);
              }}>
                <div className='flex flex-row justify-between mb-2'>
                  <div className='flex flex-col'>
                    <div className='text-xs'>{log.date.slice(0, 4)}.{log.date.slice(4, 6)}.{log.date.slice(6, 8)} {log.date.slice(8, 10)}:{log.date.slice(10, 12)}:{log.date.slice(12, 14)}</div>
                    <div className='text-xs'>{log.map}</div>
                  </div>
                  <button className='btn btn-xs btn-error text-[10px] p-1' onClick={() => {
                    setHuntingLog(huntingLog.filter(item => item.id !== log.id));
                  }}>
                    삭제
                  </button>
                </div>
                <div className='text-xs'>
                  사냥 시간: {formatTimeString(formatTime(log.time))}
                </div>
                <div className='text-xs'>레벨 업: {getLvup(log.startLv, log.startExp, log.endLv, log.endExp)}</div>
                <div className='text-xs'>
                  경험치 획득: {fNum(getExp(log.startLv, log.startExp, log.endLv, log.endExp) < 0 ? 0 : getExp(log.startLv, log.startExp, log.endLv, log.endExp))}
                  <span className='text-xs ml-2'>
                    (5분당 {fNum(calc5Min(getExp(log.startLv, log.startExp, log.endLv, log.endExp) < 0
                    ?
                    0
                    :
                    getExp(log.startLv, log.startExp, log.endLv, log.endExp), log.time))})
                  </span>
                </div>
                <div className='text-xs flex flex-row'>
                  <img className='object-contain' width={12} height={12} src={log.selectedPotion['hp']['image']} alt={log.selectedPotion['hp']['name']}/>
                  <span className='text-xs ml-2'>
                    {fNum(log.startHP - log.endHP < 0 ? 0 : log.startHP - log.endHP)}
                  </span>
                  <span className='text-xs ml-2'>
                    (5분당 {fNum(calc5Min(log.startHP - log.endHP < 0 ? 0 : log.startHP - log.endHP, log.time))})
                  </span>
                </div>

                <div className='text-xs flex flex-row'>
                  <img className='object-contain' width={12} height={12} src={log.selectedPotion['mp']['image']} alt={log.selectedPotion['mp']['name']}/>
                  <span className='text-xs ml-2'>
                    {fNum(log.startMP - log.endMP < 0 ? 0 : log.startMP - log.endMP)}
                  </span>
                  <span className='text-xs ml-2'>
                    (5분당 {fNum(calc5Min(log.startMP - log.endMP < 0 ? 0 : log.startMP - log.endMP, log.time))})
                  </span>
                </div>
                <div className='text-xs flex flex-row'>
                  <img className='object-contain' width={12} height={12} src={log.selectedPotion['buff']['image']} alt={log.selectedPotion['buff']['name']}/>
                  <span className='text-xs ml-2'>
                    {fNum(log.startBuff - log.endBuff < 0 ? 0 : log.startBuff - log.endBuff)}
                  </span>
                  <span className='text-xs ml-2'>
                    (5분당 {fNum(calc5Min(log.startBuff - log.endBuff < 0 ? 0 : log.startBuff - log.endBuff, log.time))})
                  </span>
                </div>
                <div className='text-xs'>
                  메소 획득: {fNum(log.endMeso - log.startMeso < 0 ? 0 : log.endMeso - log.startMeso)}
                  <span className='text-xs ml-2'>
                    (5분당 {fNum(calc5Min(log.endMeso - log.startMeso < 0 ? 0 : log.endMeso - log.startMeso, log.time))})
                  </span>
                </div>
                <div className='text-xs flex flex-row'>
                  <span className='text-xs'>
                    메소 지출: {
                      fNum((log.startHP - log.endHP < 0 || log.priceHP < 0 ? 0 : (log.startHP - log.endHP) * log.priceHP)
                      + (log.startMP - log.endMP < 0 || log.priceMP < 0 ? 0 : (log.startMP - log.endMP) * log.priceMP)
                      + (log.startBuff - log.endBuff < 0 || log.priceBuff < 0 ? 0 : (log.startBuff - log.endBuff) * log.priceBuff))
                    }
                  </span>
                  <span className='text-xs ml-2'>
                    (5분당 {
                      fNum(calc5Min((log.startHP - log.endHP < 0 || log.priceHP < 0 ? 0 : (log.startHP - log.endHP) * log.priceHP)
                      + (log.startMP - log.endMP < 0 || log.priceMP < 0 ? 0 : (log.startMP - log.endMP) * log.priceMP)
                      + (log.startBuff - log.endBuff < 0 || log.priceBuff < 0 ? 0 : (log.startBuff - log.endBuff) * log.priceBuff), log.time))
                    })
                  </span>
                </div>
                <div className='text-xs flex flex-row'>
                  <span className='text-xs'>
                    메소 순수익: 
                    {fNum(calcPureIncome(
                      log.startMeso, 
                      log.endMeso, 
                      log.startHP, 
                      log.endHP, 
                      log.startMP, 
                      log.endMP, 
                      log.startBuff, 
                      log.endBuff, 
                      log.priceHP, 
                      log.priceMP, 
                      log.priceBuff
                    ))}
                  </span>
                  <span className='text-xs ml-2'>
                    (5분당 {fNum(calc5Min(calcPureIncome(
                      log.startMeso, 
                      log.endMeso, 
                      log.startHP, 
                      log.endHP, 
                      log.startMP, 
                      log.endMP, 
                      log.startBuff, 
                      log.endBuff, 
                      log.priceHP, 
                      log.priceMP, 
                      log.priceBuff
                    ), log.time))})
                  </span>
                </div>
                <div className='flex flex-row gap-2'>
                  {
                    Object.keys(log.itemAcquired).length > 0
                    ?
                    Object.entries(log.itemAcquired).map(item => (
                      <div key={item[0]} className='flex flex-row mt-2 mb-2'>
                        <span className='right-0 text-[8px] text-center'>{item[1]["count"]}</span>
                        <img className='mr-1' width={14} src={item[1]["image"]} alt={item[0]}/>
                      </div>
                    ))
                    :
                    ""
                  }
                </div>
              </div>
            ))
            :
            <span className='text-center text-xs p-6'>기록 없음</span>
          }
        </div>
      </div>
    )
  }

  const calcPureIncome = (logStartMeso, logEndMeso, logStartHP, logEndHP, logStartMP, logEndMP, logStartBuff, logEndBuff, logPriceHP, logPriceMP, logPriceBuff) => {
    return logEndMeso - logStartMeso - (
      ((logStartHP - logEndHP) * logPriceHP)
      + ((logStartMP - logEndMP) * logPriceMP)
      + ((logStartBuff - logEndBuff) * logPriceBuff)
    );
  }

  return (
    <div style={{
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center',
      padding: '10px'
    }}>
      <div className="fixed top-4 right-4 dropdown dropdown-end mb-72">
        <div tabIndex={0} role="button" className="btn btn-sm m-1">
          Theme
          <svg width="12px" height="12px" className="h-2 w-2 fill-current opacity-60 inline-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2048 2048"><path d="M1799 349l242 241-1017 1017L7 590l242-241 775 775 775-775z"></path></svg>
        </div>
        <ul tabIndex={0} className="dropdown-content z-[1] p-2 shadow-2xl bg-base-300 rounded-box w-52">
          <li><input type="radio" name="theme-dropdown" className="theme-controller btn btn-sm btn-block btn-ghost justify-start" aria-label="Bumblebee" value="bumblebee"/></li>
          <li><input type="radio" name="theme-dropdown" className="theme-controller btn btn-sm btn-block btn-ghost justify-start" aria-label="Dim" value="dim"/></li>
          <li><input type="radio" name="theme-dropdown" className="theme-controller btn btn-sm btn-block btn-ghost justify-start" aria-label="Retro" value="retro"/></li>
          <li><input type="radio" name="theme-dropdown" className="theme-controller btn btn-sm btn-block btn-ghost justify-start" aria-label="Cyberpunk" value="cyberpunk"/></li>
          <li><input type="radio" name="theme-dropdown" className="theme-controller btn btn-sm btn-block btn-ghost justify-start" aria-label="Valentine" value="valentine"/></li>
          <li><input type="radio" name="theme-dropdown" className="theme-controller btn btn-sm btn-block btn-ghost justify-start" aria-label="Aqua" value="aqua"/></li>
        </ul>
      </div>
      <h1 className='font-bold text-xl mb-2'>메이플랜드 사냥 타이머</h1>
      <div style={{position: 'relative'}}>
        <label className="input input-bordered flex items-center gap-2 mb-4">
          <input onChange={mapInputEvent} onBlur={mapInputBlurEvent} value={mapInput} type="text" className="grow" placeholder="Search Map..." />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor" className="w-4 h-4 opacity-70"><path fillRule="evenodd" d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z" clipRule="evenodd" /></svg>
        </label>
        {showSearchResult ? searchResultComponent() : ""}
      </div>
      {
        selectedMap
        ?
        titleComponent()
        :
        ""
      }
      <div className='flex flex-row flex-wrap justify-center'>
        <div className='flex flex-col w-80 m-2'>
          <div className='card flex flex-col items-center justify-center bg-base-200 p-4 w-full'>
            <label className="swap font-bold">
              <input type="checkbox" onClick={() => {
                setClockMode(!clockMode);
                setTimeout(() => {
                  resetTimer();
                }, 100);
              }} value={clockMode}/>
              <div className="swap-on">스톱워치</div>
              <div className="swap-off text-center">타이머</div>
            </label>
            <p className='text-xs mb-1'>↑ 클릭하여 모드 변경</p>
            {formatTimeComponent(formatTime(elapsedTime))}
              {
                clockMode
                ?
                ""
                :
                <div>
                  <button className='btn btn-sm m-1 btn-info mt-3' onClick={() => {
                    setElapsedTime(elapsedTime + 5000 * 60);
                    setStartElapsedTime(startElapsedTime + 5000 * 60);
                  }}>+5분</button>
                  <button className='btn btn-sm m-1 btn-info mt-3' onClick={() => {
                    setElapsedTime(elapsedTime + 15000 * 60);
                    setStartElapsedTime(startElapsedTime + 15000 * 60);
                  }}>+15분</button>
                  <button className='btn btn-sm m-1 btn-info mt-3' onClick={() => {
                    setElapsedTime(elapsedTime + 60000 * 60);
                    setStartElapsedTime(startElapsedTime + 60000 * 60);
                  }}>+1시간</button>
                </div>
              }
            <div className='flex flex-row items-center justify-center p-2'>
              <button className='btn m-1 btn-success' onClick={startTimer}>시작</button>
              <button className='btn m-1 btn-warning' onClick={pauseTimer}>정지</button>
              <button className='btn m-1 btn-error' onClick={resetTimer}>리셋</button>
            </div>
            {
              clockMode
              ?
              ""
              :
              <div className="flex flex-col items-center form-control">
                <label className="label cursor-pointer w-fit">
                  <span className="label-text font-bold">알람듣기</span> 
                  <input type="checkbox" checked={isAlarm} onChange={(e) => {
                    audio.pause();
                    audio.currentTime = 0;
                    setIsAlarm(e.target.checked); 
                  }} className="checkbox checkbox-sm ml-1" />
                </label>
                <span className="label-text font-bold text-xs">(소리 재생 중에 클릭하면 소리가 꺼져요)</span> 
              </div>
            }
          </div>
          {
            selectedMap && mapData[selectedMap]
            ?
            itemListComponent()
            :
            ""
          }
          {/* 광고 */}
          <div className='flex flex-row justify-center items-center mt-2 mb-2'>
            <Adfit />
          </div>
          {huntingLogComponent()}
        </div>
        <div className='card bg-base-200 p-4 m-2 flex flex-col w-80 h-fit'>
          <label className="input input-sm input-bordered flex items-center gap-2 mb-1">
            <span className='min-w-20'>시작 레벨</span>
            <input type="number" className="grow text-end w-full" placeholder='0' value={startLv} onChange={(e) => { setStartLv(e.target.value) }}/>
          </label>
          <label className="input input-sm input-bordered flex items-center gap-2 mb-1">
            <span className='min-w-20'>시작 경험치</span>
            <input type="number" className="grow text-end w-full" placeholder='0' value={startExp} onChange={(e) => { setStartExp(e.target.value) }}/>
          </label>
          <label className="input input-sm input-bordered flex items-center gap-2 mb-1">
            <span className='min-w-20'>시작 메소</span>
            <input type="number" className="grow text-end w-full" placeholder='0' value={startMeso} onChange={(e) => { setStartMeso(e.target.value) }}/>
          </label>
          <div className='flex flex-row mb-1'>
            <label className="input input-sm input-bordered flex items-center gap-2 w-1/3">
              {potionSelectComponent('hp')}
              <input type="number" className="grow text-end w-full" placeholder='0' value={startHP} onChange={(e) => { setStartHP(e.target.value) }}/>
            </label>
            <label className="input input-sm input-bordered flex items-center gap-2 w-1/3">
              {potionSelectComponent('mp')}
              <input type="number" className="grow text-end w-full" placeholder='0' value={startMP} onChange={(e) => { setStartMP(e.target.value) }}/>
            </label>
            <label className="input input-sm input-bordered flex items-center gap-2 w-1/3">
              {potionSelectComponent('buff')}
              <input type="number" className="grow text-end w-full" placeholder='0' value={startBuff} onChange={(e) => { setStartBuff(e.target.value) }}/>
            </label>
          </div>
          <span className='text-xs text-center mb-1'>↓ 포션의 개당 가격을 입력하세요.</span>
          <div className='flex flex-row mb-1'>
            <label className="input input-sm input-bordered flex items-center gap-2 w-1/3">
              <img width={17} src={selectedPotion['hp']['image']} alt={selectedPotion['hp']['name']}/>
              <input type="number" className="grow text-end w-full" placeholder='0' value={priceHP} onChange={(e) => { setPriceHP(e.target.value) }}/>
            </label>
            <label className="input input-sm input-bordered flex items-center gap-2 w-1/3">
              <img width={17} src={selectedPotion['mp']['image']} alt={selectedPotion['mp']['name']}/>
              <input type="number" className="grow text-end w-full" placeholder='0' value={priceMP} onChange={(e) => { setPriceMP(e.target.value) }}/>
            </label>
            <label className="input input-sm input-bordered flex items-center gap-2 w-1/3">
              <img width={17} src={selectedPotion['buff']['image']} alt={selectedPotion['buff']['name']}/>
              <input type="number" className="grow text-end w-full" placeholder='0' value={priceBuff} onChange={(e) => { setPriceBuff(e.target.value) }}/>
            </label>
          </div>
          <div className='flex flex-row items-center justify-center'>
            <button 
              onClick={() => {
                const startLv_ = startLv;
                const startExp_ = startExp;
                const startMeso_ = startMeso;
                const startHP_ = startHP;
                const startMP_ = startMP;
                const startBuff_ = startBuff;
                const endLv_ = endLv;
                const endExp_ = endExp;
                const endMeso_ = endMeso;
                const endHP_ = endHP;
                const endMP_ = endMP;
                const endBuff_ = endBuff;

                setStartLv(endLv_);
                setStartExp(endExp_);
                setStartMeso(endMeso_);
                setStartHP(endHP_);
                setStartMP(endMP_);
                setStartBuff(endBuff_);
                setEndLv(startLv_);
                setEndExp(startExp_);
                setEndMeso(startMeso_);
                setEndHP(startHP_);
                setEndMP(startMP_);
                setEndBuff(startBuff_);
              }}
              className='btn btn-circle btn-xs btn-ghost mb-1'>↑↓</button>
          </div>
          <label className="input input-sm input-bordered flex items-center gap-2 mb-1">
            <span className='min-w-20'>종료 레벨</span>
            <input type="number" className="grow text-end w-full" placeholder='0' value={endLv} onChange={(e) => { setEndLv(e.target.value) }}/>
          </label>
          <label className="input input-sm input-bordered flex items-center gap-2 mb-1">
            <span className='min-w-20'>종료 경험치</span>
            <input type="number" className="grow text-end w-full" placeholder='0' value={endExp} onChange={(e) => { setEndExp(e.target.value) }}/>
          </label>
          <label className="input input-sm input-bordered flex items-center gap-2 mb-1">
            <span className='min-w-20'>종료 메소</span>
            <input type="number" className="grow text-end w-full" placeholder='0' value={endMeso} onChange={(e) => { setEndMeso(e.target.value) }}/>
          </label>
          <div className='flex flex-row'>
            <label className="input input-sm input-bordered flex items-center gap-2 w-1/3">
              <img width={17} src={selectedPotion['hp']['image']} alt={selectedPotion['hp']['name']}/>
              <input type="number" className="grow text-end w-full" placeholder='0' value={endHP} onChange={(e) => { setEndHP(e.target.value) }}/>
            </label>
            <label className="input input-sm input-bordered flex items-center gap-2 w-1/3">
              <img width={17} src={selectedPotion['mp']['image']} alt={selectedPotion['mp']['name']}/>
              <input type="number" className="grow text-end w-full" placeholder='0' value={endMP} onChange={(e) => { setEndMP(e.target.value) }}/>
            </label>
            <label className="input input-sm input-bordered flex items-center gap-2 w-1/3">
              <img width={17} src={selectedPotion['buff']['image']} alt={selectedPotion['buff']['name']}/>
              <input type="number" className="grow text-end w-full" placeholder='0' value={endBuff} onChange={(e) => { setEndBuff(e.target.value) }}/>
            </label>
          </div>
          <div className="divider"></div>
          <div id='capture' className="stats stats-vertical shadow relative"
            onMouseEnter={() => {
              setSaveOn(true);
            }}
            onMouseLeave={() => {
              setSaveOn(false);
            }}
          >
            <div className="stat">
              {
                saveOn
                ?
                <button id='save-button' className="btn btn-square btn-ghost btn-active shadow btn-sm absolute top-2 right-2"
                  onClick={() => {
                    const saveButton = document.getElementById('save-button');
                    saveButton.style.display = "none";
                    htmlToImage.toPng(document.getElementById('capture'))
                    .then((dataUrl) => {
                      const link = document.createElement('a');
                      link.download = `maple_timer_capture_${getDate()}.png`;
                      link.href = dataUrl;
                      link.click();
                      saveButton.style.display = "block";
                    });
                  }}
                >
                  <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABoklEQVR4nO2YMUsDMRiGn6si6ig4O6joz7jBzUEL4uBvqI4OThYRpAp20UknR0G7WAWHFpz8A1J/gGMHUagdRAmkEEKL6fW7Xip54INc7/J+33vJXXOBQCAQCASSswyUgDvgsc9QfUpaIxN2gTbwM2C0tdbQGAPOBAq34xIYT7v4KaBiJa4Dq8BKn6H61C2tis6RCjPAk5XwGpgcQHMCuLI0n4FZhJkDGlaiIyAS0I60lqnd0DlFmAZeDfFvoIA8Ba1tmhCZTjspPLCusS1h4DZDAzcSBmoZGqhJG4hJnzgYEByBKMGrNvZlBNQi7U3H0iga2DP6qfbIGdg3+qm2K3EwYBFGgBGZQofAJ1AGcl2egZw+p6458NHAh3HdBVA0jov6t87xu48GytY6ptmjreLURwMRcO747Zvz0YCLib+K9+J/oJcJl+K9MNDNhGvx3hjomNjS0c+KNJY28GAIrpE+60a+ewnBE0PwBcgn2MRyjbzO0cmn9k4HZhH4yuB7uAXMI8TmkE20gA2EWQCOgWqCrXTXqOppI3bnA4FAIPC/+QXIGMUiDLJC4QAAAABJRU5ErkJggg==" />
                </button>
                : ""
              }
              <div className="stat-title text-center">{selectedMap}</div>
              <div className="stat-desc text-center">{getTimeString(elapsedTime)}</div>
            </div>

            <div className="stat">
              <div className="stat-title">레벨 업</div>
              <div className="stat-value">{endLv - startLv < 0 ? 0 : endLv - startLv}</div>
              <div className="stat-desc">{getLvup(startLv, startExp, endLv, endExp)}</div>
            </div>

            <div className="stat">
              <div className="stat-title">획득한 경험치</div>
              <div className="stat-value">{fNum(getExp(startLv, startExp, endLv, endExp) < 0 ? 0 : getExp(startLv, startExp, endLv, endExp))}</div>
              <div className="stat-desc">5분당 {fNum(calc5Min(getExp(startLv, startExp, endLv, endExp) < 0 ? 0 : getExp(startLv, startExp, endLv, endExp), elapsedTime))}</div>
            </div>
            
            <div className="stat">
              <div className="stat-title">획득한 메소</div>
              <div className="stat-value">{fNum(endMeso - startMeso < 0 ? 0 : endMeso - startMeso)}</div>
              <div className="stat-desc">5분당 {fNum(calc5Min(endMeso - startMeso < 0 ? 0 : endMeso - startMeso, elapsedTime))}</div>
            </div>

            <div className="stat">
              <div className="stat-title">사용한 포션</div>
              <div>
                <div className='flex flex-row mb-2 mt-2'>
                  <img className='object-contain' width={24} height={24} src={selectedPotion['hp']['image']} alt={selectedPotion['hp']['name']}/>
                  <div className='ml-3 flex flex-col'>
                    <span className='font-bold text-lg'>{fNum(startHP - endHP < 0 ? 0 : startHP - endHP)}</span>
                    <span className='stat-desc'>
                      {fNum(startHP - endHP < 0 ? 0 : (startHP - endHP) * priceHP)} 메소
                    </span>
                  </div>
                </div>
                <div className='flex flex-row mb-2'>
                  <img className='object-contain' width={24} src={selectedPotion['mp']['image']} alt={selectedPotion['mp']['name']}/>
                  <div className='ml-3 flex flex-col'>
                    <span className='font-bold text-lg'>{fNum(startMP - endMP < 0 ? 0 : startMP - endMP)}</span>
                    <span className='stat-desc'>
                      {fNum(startMP - endMP < 0 ? 0 : (startMP - endMP) * priceMP)} 메소
                    </span>
                  </div>
                </div>
                <div className='flex flex-row'>
                  <img className='object-contain' width={24} src={selectedPotion['buff']['image']} alt={selectedPotion['buff']['name']}/>
                  <div className='ml-3 flex flex-col'>
                    <span className='font-bold text-lg'>{fNum(startBuff - endBuff < 0 ? 0 : startBuff - endBuff)}</span>
                    <span className='stat-desc'>
                      {fNum(startBuff - endBuff < 0 ? 0 : (startBuff - endBuff) * priceBuff)} 메소
                    </span>
                  </div>
                </div>
              </div>
              <div className='text-end flex flex-col'>
                <span className='stat-desc'>
                  총 {
                    fNum((startHP - endHP < 0 || priceHP < 0 ? 0 : (startHP - endHP) * priceHP)
                     + (startMP - endMP < 0 || priceMP < 0 ? 0 : (startMP - endMP) * priceMP)
                     + (startBuff - endBuff < 0 || priceBuff < 0 ? 0 : (startBuff - endBuff) * priceBuff))
                  } 메소
                </span>
                <span className='stat-desc'>
                  5분당 {
                    fNum(calc5Min((startHP - endHP < 0 || priceHP < 0 ? 0 : (startHP - endHP) * priceHP)
                     + (startMP - endMP < 0 || priceMP < 0 ? 0 : (startMP - endMP) * priceMP)
                     + (startBuff - endBuff < 0 || priceBuff < 0 ? 0 : (startBuff - endBuff) * priceBuff), elapsedTime))
                  } 메소
                </span>
              </div>
            </div>

            <div className='stat'>
              <div className='stat-title'>메소 순수익</div>
              <div className='stat-value'>
                {fNum(calcPureIncome(startMeso, endMeso, startHP, endHP, startMP, endMP, startBuff, endBuff, priceHP, priceMP, priceBuff))}
              </div>
              <div className='stat-desc'>5분당 {fNum(calc5Min(calcPureIncome(startMeso, endMeso, startHP, endHP, startMP, endMP, startBuff, endBuff, priceHP, priceMP, priceBuff), elapsedTime))}</div>
            </div>
            
            <div className="stat">
              <div className="stat-title">획득한 아이템</div>
              <div>
                {
                  Object.keys(itemAcquired).length
                  ?
                  itemAcquiredComponent()
                  :
                  <p className='text-xs w-52 pt-2' style={{wordBreak: 'keep-all'}}>몹 드랍 아이템 목록에서 획득한 아이템을 선택하세요.</p>
                }
              </div>
            </div>
            <div className="stat">
              <div className="stat-desc text-center">mapletimer.com</div>
            </div>
          </div>
          <div className='flex flex-row items-center justify-center mt-4'>
            <button className='btn btn-sm btn-info w-full' onClick={saveHuntingLog}>사냥 기록 남기기</button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Timer;